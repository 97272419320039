import { Popover } from '@headlessui/react';
import { useAuthenticationContext } from 'components/providers/authentication-provider';
import { useToast } from 'hooks';
import {
  filter,
  gt,
  includes,
  isEmpty,
  isEqual,
  isNil,
  isNumber,
  isUndefined,
  map,
  reduce,
  toNumber,
  upperFirst,
} from 'lodash';
import moment from 'moment-timezone';
import Link from 'next/link';
import { Fragment, MouseEvent, useId } from 'react';
import { BiArea, BiBath } from 'react-icons/bi';
import { BsCardImage, BsFillCameraVideoFill, BsSuitHeartFill } from 'react-icons/bs';
import { MdOutlineKingBed, MdOutlineLocationOn } from 'react-icons/md';
import { TbStairsUp } from 'react-icons/tb';
import { convertNumberToVietnameseCurrencyShortString } from 'utils';
import { mainClient } from '../../../../apollo/client';
import {
  FileWithFullUrls,
  GET_REAL_ESTATES,
  RealEstate,
} from '../../../../graphql/main/queries/get-real-estates';
import SavePostPopover from '../save-post-popover';
import ToastStack from '../toast-stack';

interface Props {
  size?: 'medium' | 'small';
  type?: 'normal' | 'vip' | 'authorized' | 'special' | 'deposit';
  realEstate?: RealEstate;
}

const RealEstateHorizontalCard = ({ size, type, realEstate }: Props) => {
  const isSizeSmall = isEqual(size, 'small');
  const { currentUser } = useAuthenticationContext();
  const { toasts, addToast } = useToast();
  const id = useId();
  const mediaCountObjects = [
    {
      types: ['video', 'youtube'],
      icon: <BsFillCameraVideoFill className='h-[10px] w-[10px]' />,
      count: 0,
    },
    {
      types: ['image'],
      icon: <BsCardImage className='h-[10px] w-[10px]' />,
      count: 0,
    },
  ];
  const smallPropertyObjects = [
    {
      icon: <BiArea className='h-[16px] w-[16px] text-text2' />,
      content: realEstate?.area ? `${realEstate?.area.toLocaleString()} m\u00B2` : '-',
    },
    {
      icon: <BiBath className='h-[16px] w-[16px] text-text2' />,
      content: realEstate?.toiletCount ? realEstate?.toiletCount.toLocaleString() : '-',
    },
    {
      icon: <MdOutlineKingBed className='h-[16px] w-[16px] text-text2' />,
      content: realEstate?.bedroomCount ? realEstate?.bedroomCount.toLocaleString() : '-',
    },
  ];
  const mediumPropertyObjects = [
    {
      icon: <BiArea className='h-[20px] w-[20px] text-text2' />,
      content: realEstate?.area ? `${realEstate?.area.toLocaleString()} m\u00B2` : '-',
    },
    {
      icon: <BiBath className='h-[20px] w-[20px] text-text2' />,
      content: realEstate?.toiletCount ? realEstate?.toiletCount.toLocaleString() : '-',
    },
    {
      icon: <MdOutlineKingBed className='h-[20px] w-[20px] text-text2' />,
      content: realEstate?.bedroomCount ? realEstate?.bedroomCount.toLocaleString() : '-',
    },
    {
      icon: <TbStairsUp className='h-[20px] w-[20px] text-text2' />,
      content: realEstate?.floorCount ? `${realEstate?.floorCount.toLocaleString()} tầng` : '-',
    },
  ];

  const handleOnClickSavePostModal = (event: MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const handleOnSaveRealEstate = (type: 'add' | 'update' | 'delete') => {
    isEqual(type, 'add')
      ? addToast('success', 'Lưu tin đăng thành công!')
      : isEqual(type, 'update')
      ? addToast('success', 'Cập nhật lưu tin thành công!')
      : isEqual(type, 'delete')
      ? addToast('success', 'Hủy lưu tin thành công!')
      : null;
    mainClient.refetchQueries({
      include: [GET_REAL_ESTATES],
    });
  };
  return (
    <Fragment>
      {isSizeSmall ? (
        <Link href={!realEstate?.fullSlug ? '#' : `/${realEstate?.fullSlug}`}>
          <a>
            <div id={id} className='group flex w-full flex-col space-y-[12px]'>
              <span
                className={`font-[600] line-clamp-2 ${
                  isEqual(type, 'special')
                    ? 'text-badge'
                    : isEqual(type, 'vip')
                    ? 'text-accent1'
                    : isEqual(type, 'authorized') || isEqual(type, 'deposit')
                    ? 'text-primary'
                    : ''
                }`}
              >
                {upperFirst(realEstate?.postTitle)}
              </span>
              <div className='flex space-x-[12px]'>
                <div className='relative h-[125px] min-w-[140px] max-w-[140px] overflow-hidden rounded-[8px]'>
                  <img
                    src={
                      (realEstate?.mediaUrls?.[0] as FileWithFullUrls)?.originalUrl ??
                      '/images/error-image.svg'
                    }
                    alt={realEstate?.postTitle}
                    className='h-[125px] min-w-[140px] max-w-[140px] object-cover transition-all duration-[500ms] ease-in-out group-hover:scale-[1.1]'
                  />
                  {!isNil(realEstate?.isForSell) && (
                    <span
                      className={`absolute top-[11px] left-0 rounded-tr-[16px] px-[10px] py-[6px] text-[12px] font-[600] uppercase leading-[15px] text-paper ${
                        realEstate?.isForSell ? 'bg-accent1' : 'bg-accent2'
                      }`}
                    >
                      {realEstate?.isForSell ? 'bán' : 'thuê'}
                    </span>
                  )}
                  <div
                    className={`absolute top-[4px] right-[4px] h-[40px] w-[45px] bg-cover bg-center bg-no-repeat ${
                      isEqual(type, 'special')
                        ? 'bg-[url("/logos/special-stamp.svg")]'
                        : isEqual(type, 'vip')
                        ? 'bg-[url("/logos/vip-stamp.svg")]'
                        : isEqual(type, 'authorized')
                        ? 'bg-[url("/logos/authorized-stamp.svg")]'
                        : isEqual(type, 'deposit')
                        ? 'bg-[url("/logos/deposit-stamp.svg")]'
                        : ''
                    }`}
                  />
                  {!isEmpty(realEstate?.mediaUrls) && (
                    <div className='absolute bottom-[5px] right-[4px] flex flex-col space-y-[4px]'>
                      {map(
                        filter(
                          reduce(
                            realEstate?.mediaUrls,
                            (accumulator, mediaUrl) =>
                              map(accumulator, (item) => {
                                if (includes(item.types, mediaUrl.type)) {
                                  return { ...item, count: item.count + 1 };
                                }

                                return item;
                              }),
                            mediaCountObjects,
                          ),
                          (mediaCountObject) => gt(mediaCountObject.count, 0),
                        ),
                        (mediaCountObject, mediaCountObjectIndex) => (
                          <div
                            key={mediaCountObjectIndex}
                            className='flex items-center justify-center space-x-[4px] rounded-[4px] bg-[#000000]/[.2] px-[12px] py-[4px] text-paper'
                          >
                            <span className='text-[10px] leading-[13px]'>
                              {mediaCountObject.count}
                            </span>
                            {mediaCountObject.icon}
                          </div>
                        ),
                      )}
                    </div>
                  )}
                </div>
                <div className='flex w-full flex-col justify-between space-y-[19px] overflow-hidden'>
                  <div className='flex min-h-[16px] items-center space-x-[24px]'>
                    <div className='flex w-full items-center space-x-[8px]'>
                      <div className='min-h-[16px] min-w-[16px] bg-[url("/icons/price-tag-red.svg")] bg-cover bg-center bg-no-repeat' />
                      <span className='w-full truncate text-[14px] font-[600] leading-[16px] text-alert'>
                        {isNil(realEstate?.price) ||
                        !isNumber(toNumber(realEstate?.price)) ||
                        !toNumber(realEstate?.price)
                          ? 'Thương lượng'
                          : `${convertNumberToVietnameseCurrencyShortString(
                              toNumber(realEstate?.price),
                            )}${realEstate?.isForSell ? '' : '/tháng'}`}
                      </span>
                    </div>
                    <span className='w-full truncate text-right text-[14px] leading-[16px]'>
                      {!realEstate?.isForSell ||
                      isNil(realEstate?.price) ||
                      !isNumber(toNumber(realEstate?.price)) ||
                      !toNumber(realEstate?.price) ||
                      isNil(realEstate?.area)
                        ? null
                        : `${convertNumberToVietnameseCurrencyShortString(
                            toNumber(realEstate?.price) / toNumber(realEstate?.area),
                          )}/m\u00B2`}
                    </span>
                  </div>
                  <div className='flex min-h-[16px] space-x-[24px]'>
                    {map(
                      filter(smallPropertyObjects, (propertyObject) =>
                        Boolean(propertyObject.content),
                      ),
                      (propertyObject, propertyObjectIndex) => (
                        <div key={propertyObjectIndex} className='flex items-center space-x-[10px]'>
                          {propertyObject.icon}
                          <span className='truncate text-[12px] leading-[15px]'>
                            {propertyObject.content}
                          </span>
                        </div>
                      ),
                    )}
                  </div>
                  <div className='flex min-h-[16px] items-center space-x-[8px]'>
                    <MdOutlineLocationOn className='min-h-[16px] min-w-[16px] text-text2' />
                    <span className='truncate text-[12px] leading-[15px]'>
                      {realEstate?.shortAddress}
                    </span>
                  </div>
                  <div className='flex min-h-[20px] items-center justify-between space-x-[24px]'>
                    {realEstate?.createdAt && (
                      <span className='w-full truncate text-[12px] leading-[15px] text-text2'>
                        {moment(realEstate.createdAt).format('DD/MM/YYYY')}
                      </span>
                    )}
                    <div className='flex w-full items-center justify-end space-x-[12px]'>
                      {currentUser?.id &&
                        realEstate?.creator?.id &&
                        isEqual(currentUser.id, realEstate?.creator.id) &&
                        realEstate?.privateNote && (
                          <div className='h-[20px] w-[20px] bg-[url("/icons/note.svg")] bg-cover bg-center bg-no-repeat' />
                        )}
                      {!isNil(currentUser) && (
                        <Popover>
                          <Popover.Button
                            className={`flex h-[20px] w-[20px] items-center justify-center rounded-full ${
                              realEstate?.isSaved
                                ? 'bg-primary'
                                : 'border border-paper bg-[#000000]/[.2]'
                            }`}
                          >
                            <BsSuitHeartFill className='h-[10px] w-[10px] text-paper' />
                          </Popover.Button>
                          <Popover.Panel
                            className='absolute top-[50px] right-[10px] z-[10]'
                            onClick={handleOnClickSavePostModal}
                          >
                            {({ close }) =>
                              !isUndefined(realEstate?.id) && !isUndefined(realEstate) ? (
                                <SavePostPopover
                                  realEstateId={realEstate.id}
                                  realEstateType={realEstate.realEstateType}
                                  isSaved={realEstate.isSaved}
                                  saveItem={realEstate.saveItem}
                                  onClose={() => close()}
                                  onCompleted={handleOnSaveRealEstate}
                                />
                              ) : (
                                <Fragment />
                              )
                            }
                          </Popover.Panel>
                        </Popover>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </a>
        </Link>
      ) : (
        <Link href={!realEstate?.fullSlug ? '#' : `/${realEstate?.fullSlug}`}>
          <a className='relative'>
            <div
              id={id}
              className='group flex h-[230px] w-full overflow-hidden rounded-[8px] transition-all duration-[500ms] ease-in-out hover:shadow-6'
            >
              <div className='relative'>
                <div className='overflow-hidden'>
                  <img
                    src={
                      (realEstate?.mediaUrls?.[0] as FileWithFullUrls)?.originalUrl ??
                      '/images/error-image.svg'
                    }
                    alt={realEstate?.postTitle}
                    className='h-[230px] min-w-[345px] max-w-[345px] object-cover transition-all duration-[500ms] ease-in-out group-hover:scale-[1.1]'
                  />
                </div>
                {!isNil(realEstate?.isForSell) && (
                  <span
                    className={`absolute top-[11px] left-0 rounded-tr-[16px] px-[10px] py-[6px] text-[12px] font-[600] uppercase leading-[15px] text-paper ${
                      realEstate?.isForSell ? 'bg-accent1' : 'bg-accent2'
                    }`}
                  >
                    {realEstate?.isForSell ? 'bán' : 'thuê'}
                  </span>
                )}
                <div
                  className={`absolute top-[48px] left-[5px] h-[52px] w-[59px] bg-cover bg-center bg-no-repeat ${
                    isEqual(type, 'special')
                      ? 'bg-[url("/logos/special-stamp.svg")]'
                      : isEqual(type, 'vip')
                      ? 'bg-[url("/logos/vip-stamp.svg")]'
                      : isEqual(type, 'authorized')
                      ? 'bg-[url("/logos/authorized-stamp.svg")]'
                      : isEqual(type, 'deposit')
                      ? 'bg-[url("/logos/deposit-stamp.svg")]'
                      : ''
                  }`}
                />
                {!isEmpty(realEstate?.mediaUrls) && (
                  <div className='absolute bottom-[11px] right-[9px] flex flex-col space-y-[6px]'>
                    {map(
                      filter(
                        reduce(
                          realEstate?.mediaUrls,
                          (accumulator, mediaUrl) =>
                            map(accumulator, (item) => {
                              if (includes(item.types, mediaUrl.type)) {
                                return { ...item, count: item.count + 1 };
                              }

                              return item;
                            }),
                          mediaCountObjects,
                        ),
                        (mediaCountObject) => gt(mediaCountObject.count, 0),
                      ),
                      (mediaCountObject, mediaCountObjectIndex) => (
                        <div
                          key={mediaCountObjectIndex}
                          className='flex items-center justify-center space-x-[6px] rounded-[4px] bg-[#000000]/[.2] px-[11px] py-[6px] text-paper'
                        >
                          <span className='text-[12px] leading-[15px]'>
                            {mediaCountObject.count}
                          </span>
                          {mediaCountObject.icon}
                        </div>
                      ),
                    )}
                  </div>
                )}
              </div>
              <div className='flex w-full flex-col space-y-[16px] overflow-hidden py-[16px] px-[24px]'>
                <span
                  className={`min-h-[46px] text-[18px] font-[600] leading-[23px] line-clamp-2 ${
                    isEqual(type, 'special')
                      ? 'text-badge'
                      : isEqual(type, 'vip')
                      ? 'text-accent1'
                      : isEqual(type, 'authorized') || isEqual(type, 'deposit')
                      ? 'text-primary'
                      : ''
                  }`}
                >
                  {upperFirst(realEstate?.postTitle)}
                </span>
                <div className='flex min-h-[28px] space-x-[24px]'>
                  {map(
                    filter(mediumPropertyObjects, (propertyObject) =>
                      Boolean(propertyObject.content),
                    ),
                    (propertyObject, propertyObjectIndex) => (
                      <div key={propertyObjectIndex} className='flex items-center space-x-[8px]'>
                        <div className='flex space-x-[8px]'>
                          {propertyObject.icon}
                          <span className='truncate'>{propertyObject.content}</span>
                        </div>
                      </div>
                    ),
                  )}
                </div>
                <div className='flex min-h-[18px] items-center space-x-[8px]'>
                  <MdOutlineLocationOn className='min-h-[20px] min-w-[20px] text-text2' />
                  <span className='truncate'>{realEstate?.fullAddress}</span>
                </div>
                <div className='flex min-h-[23px] items-center space-x-[12px]'>
                  <div className='flex items-center space-x-[8px]'>
                    <div className='min-h-[20px] min-w-[20px] bg-[url("/icons/price-tag-red.svg")] bg-cover bg-center bg-no-repeat' />
                    <span className='truncate text-[18px] font-[600] leading-[23px] text-alert'>
                      {isNil(realEstate?.price) ||
                      !isNumber(toNumber(realEstate?.price)) ||
                      !toNumber(realEstate?.price)
                        ? 'Thương lượng'
                        : `${convertNumberToVietnameseCurrencyShortString(
                            toNumber(realEstate?.price),
                          )}${realEstate?.isForSell ? '' : '/tháng'}`}
                    </span>
                  </div>
                  <span className='text-[12px] text-stroke'>{'\u2022'}</span>
                  <span className='truncate text-[14px] leading-[16px]'>
                    {!realEstate?.isForSell ||
                    isNil(realEstate?.price) ||
                    !isNumber(toNumber(realEstate?.price)) ||
                    !toNumber(realEstate?.price) ||
                    isNil(realEstate?.area)
                      ? null
                      : `${convertNumberToVietnameseCurrencyShortString(
                          toNumber(realEstate?.price) / toNumber(realEstate?.area),
                        )}/m\u00B2`}
                  </span>
                </div>
                <div className='flex min-h-[20px] items-center justify-between space-x-[24px]'>
                  {realEstate?.createdAt && (
                    <span className='truncate text-[12px] leading-[15px] text-text2'>
                      {moment(realEstate.createdAt).format('DD/MM/YYYY')}
                    </span>
                  )}
                  <div className='flex items-center space-x-[16px]'>
                    {currentUser?.id &&
                      realEstate?.creator?.id &&
                      isEqual(currentUser.id, realEstate?.creator.id) &&
                      realEstate?.privateNote && (
                        <div className='h-[20px] w-[20px] bg-[url("/icons/note.svg")] bg-cover bg-center bg-no-repeat' />
                      )}
                    {!isNil(currentUser) && (
                      <Popover>
                        <Popover.Button
                          className={`flex h-[20px] w-[20px] items-center justify-center rounded-full ${
                            realEstate?.isSaved
                              ? 'bg-primary'
                              : 'border border-paper bg-[#000000]/[.2]'
                          }`}
                        >
                          <BsSuitHeartFill className='h-[10px] w-[10px] text-paper' />
                        </Popover.Button>
                        <Popover.Panel
                          className='absolute top-[50px] right-[10px] z-[10]'
                          onClick={handleOnClickSavePostModal}
                        >
                          {({ close }) =>
                            !isUndefined(realEstate?.id) && !isUndefined(realEstate) ? (
                              <SavePostPopover
                                realEstateId={realEstate.id}
                                realEstateType={realEstate.realEstateType}
                                isSaved={realEstate.isSaved}
                                saveItem={realEstate.saveItem}
                                onClose={() => close()}
                                onCompleted={handleOnSaveRealEstate}
                              />
                            ) : (
                              <Fragment />
                            )
                          }
                        </Popover.Panel>
                      </Popover>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </a>
        </Link>
      )}
      <ToastStack toasts={toasts} />
    </Fragment>
  );
};

export default RealEstateHorizontalCard;

export const RealEstateHorizontalCardSkeleton = ({ size }: Props) => {
  const isSizeSmall = isEqual(size, 'small');

  return isSizeSmall ? (
    <div className='flex w-full animate-pulse flex-col space-y-[12px]'>
      <div className='flex h-[18px] items-center'>
        <div className='h-[14px] w-full bg-secondary' />
      </div>
      <div className='flex space-x-[12px]'>
        <div className='min-h-[125px] min-w-[140px] rounded-[8px] bg-secondary' />
        <div className='flex w-full flex-col space-y-[19px] overflow-hidden'>
          <div className='flex h-[16px] items-center justify-between space-x-[24px]'>
            <div className='h-[14px] w-[80px] bg-secondary' />
            <div className='h-[14px] w-[60px] bg-secondary' />
          </div>
          <div className='flex h-[16px] space-x-[24px]'>
            <div className='h-[12px] w-[60px] bg-secondary' />
            <div className='h-[12px] w-[60px] bg-secondary' />
            <div className='h-[12px] w-[60px] bg-secondary' />
          </div>
          <div className='flex h-[16px] space-x-[24px]'>
            <div className='h-[12px] w-full bg-secondary' />
          </div>
          <div className='flex h-[20px] items-center justify-between space-x-[24px]'>
            <div className='h-[12px] w-[70px] bg-secondary' />
            <div className='flex items-center space-x-[12px]'>
              <div className='h-[20px] w-[20px] bg-secondary' />
              <div className='h-[20px] w-[20px] bg-secondary' />
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className='flex h-[230px] w-full animate-pulse overflow-hidden rounded-[8px]'>
      <div className='h-full min-w-[345px] bg-secondary' />
      <div className='flex w-full flex-col space-y-[16px] px-[24px] py-[16px]'>
        <div className='flex h-[46px] flex-col'>
          <div className='flex h-[23px] items-center'>
            <div className='h-[18px] w-full bg-secondary' />
          </div>
          <div className='flex h-[23px] items-center'>
            <div className='h-[18px] w-full bg-secondary' />
          </div>
        </div>
        <div className='flex h-[28px] items-center space-x-[24px]'>
          <div className='h-[14px] w-[60px] bg-secondary' />
          <div className='h-[14px] w-[60px] bg-secondary' />
          <div className='h-[14px] w-[60px] bg-secondary' />
          <div className='h-[14px] w-[60px] bg-secondary' />
        </div>
        <div className='flex h-[20px]'>
          <div className='h-[14px] w-full bg-secondary' />
        </div>
        <div className='flex h-[23px] items-center space-x-[12px]'>
          <div className='h-[18px] w-[80px] bg-secondary' />
          <div className='h-[6px] w-[6px] rounded-full bg-secondary' />
          <div className='h-[14px] w-[60px] bg-secondary' />
        </div>
        <div className='flex h-[20px] items-center justify-between space-x-[24px]'>
          <div className='h-[12px] w-[70px] bg-secondary' />
          <div className='flex items-center justify-between space-x-[24px]'>
            <div className='h-[20px] w-[20px] bg-secondary' />
            <div className='h-[20px] w-[20px] bg-secondary' />
          </div>
        </div>
      </div>
    </div>
  );
};
